'use client';

import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import { Logout } from '..';
import { AnimatePresence, motion } from 'framer-motion';
import { AppShellTop } from '.';

interface AppShellProps {
  children: React.ReactNode;
  pageTitle?: string;
  tradingName?: string;
}

const defaultProps: Partial<AppShellProps> = {
  pageTitle: '',
};

const AppShell: React.FC<AppShellProps> = ({ children, pageTitle, tradingName }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  const router = useRouter();

  const goTologout = () => {
    setLogoutModal(true);
  };

  const closeModal = () => {
    setLogoutModal(false);
  };

  const menuClicked = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="flex flex-col relative max-w-full p-20">
      {showSidebar && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }}
          className="w-screen h-screen bg-grey-1100/70 fixed grid grid-cols-sidebar z-[120] top-0 left-0 md:grid-cols-sidebar-800 sm:grid-cols-sidebar-600"
        >
          <motion.div
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              x: -100,
              transition: {
                duration: 0.2,
              },
            }}
          ></motion.div>
          <div onClick={menuClicked} className="cursor-pointer"></div>
        </motion.div>
      )}

      <AppShellTop menuClicked={menuClicked} pageTitle={pageTitle} goTologout={goTologout} />
      <div>{children}</div>

      {logoutModal && (
        <div className="modal">
          <AnimatePresence>{logoutModal && <Logout closeModal={closeModal} />}</AnimatePresence>
        </div>
      )}
    </div>
  );
};

AppShell.defaultProps = defaultProps;

export default AppShell;
