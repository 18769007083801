'use client';

import { usePathname, useRouter } from 'next/navigation';
import constant from '@/utils/constant';
import { useEffect, useState } from 'react';

export const useSwitchNav = () => {
  const [isCompetition, setIsCompetition] = useState(true);
  const router = useRouter();
  const pathname = usePathname();
  const BASE_APP_URL = process.env.NEXT_PUBLIC_BASE_APP_URL || ''; // Ensure this variable is set in the environment

  useEffect(() => {
    if (pathname.includes('competition')) {
      setIsCompetition(true);
    } else {
      setIsCompetition(false);
    }
  }, [pathname]); // Re-run whenever the route changes

  const switchToLanding = () => {
    router.push(constant.Routes.Home); // Navigates to the home route
  };

  const switchToSignup = () => {
    router.push(`${BASE_APP_URL}${constant.Routes.SignUp}`); // Concatenates the base URL and signup route
  };

  const switchToLogin = () => {
    router.push(`${BASE_APP_URL}${constant.Routes.Login}`); // Concatenates the base URL and login route
  };

  const switchToCompetition = () => {
    router.push(constant.Routes.Competition);
  };
  const switchToRegister = () => {
    router.push(constant.Routes.Register);
  };
  const switchToTermsAndConditions = () => {
    window.open(constant.Routes.TermsAndConditions, '_blank');
  };

  const goToAllContestants = (stage: string, id: string) => {
    router.push(`${constant.Routes.Contestants}?stage=${stage}&id=${id}`);
  };

  return {
    switchToLanding,
    switchToSignup,
    switchToLogin,
    isCompetition,
    setIsCompetition,
    switchToRegister,
    switchToCompetition,
    switchToTermsAndConditions,
    goToAllContestants,
  };
};
