'use client';

import { useMemo, useState } from 'react';
import IconButton from '../IconButton';
import { BsInfoCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
import { inputStyles } from './styles';
import { useController } from 'react-hook-form';

interface InputProps {
  labelTitle?: string;
  type?: 'text' | 'email' | 'number' | 'password' | 'radio' | 'date' | 'textarea' | 'tel' | 'time';
  placeholder: string;
  defaultValue?: string | number;
  id?: string;
  name: string;
  disabled?: boolean;
  variant?: 'medium' | 'form' | 'textArea' | 'error' | 'formError' | 'exchange';
  width?: any;
  height?: any;
  icon?: React.ReactElement;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  error?: any;
  touched?: boolean | undefined;
  passwordIcon?: boolean;
  fontSize?: number;
  suggestionData?: any[];
  handleFilter?: (item: any, text: string) => void;
  note?: string;
  // register: any;
  control: any;
  required?: boolean;
}

const MimaInput: React.FC<InputProps> = ({
  labelTitle,
  placeholder,
  disabled = false,
  type = 'text',
  variant = 'medium',
  width,
  height,
  id,
  name,
  mt,
  mr,
  mb,
  ml,
  touched,
  error,
  passwordIcon = false,
  fontSize,
  note,
  // register,
  control,
  required = false,
  defaultValue = '',
  ...props
}) => {
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name,
    control,
    defaultValue,
    rules: { required },
  });
  const [hide, setHide] = useState(true);

  const showpassword = () => {
    setHide(!hide);
  };

  const options = {
    disabled: disabled,
  };

  const errorVariant = useMemo(() => {
    if (variant === 'form' || variant === 'textArea') {
      return 'formError';
    } else return 'error';
  }, [variant]);

  return (
    <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
      {labelTitle && (
        <label htmlFor={id || name} className="label">
          {labelTitle}
        </label>
      )}
      <WithIconSkeleton>
        <InputSkeleton
          type={passwordIcon && hide ? 'password' : type}
          placeholder={placeholder}
          variant={error ? errorVariant : variant}
          width={width}
          name={name}
          id={id || name}
          height={height}
          fontSize={fontSize}
          // {...register(name, options)}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          control={control}
        />

        {passwordIcon && (
          <IconButton
            variant={!hide ? 'eyeOpen' : 'eyeClose'}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              showpassword();
            }}
            testID="iconButton"
          />
        )}
      </WithIconSkeleton>

      {error ? <div className="text-color-error my-4 max-w-[38rem] text-default-font-small">{error.message}</div> : null}
      {note ? (
        <div className="flex items-center text-default-font-small text-color-primary my-4">
          <BsInfoCircleFill style={{ fontSize: '2rem', marginRight: '1rem' }} /> {note}
        </div>
      ) : null}
    </DivSkeleton>
  );
};

const InputSkeleton = styled.input<Partial<InputProps>>`
  ${inputStyles.base};
  ${(props) => props.variant && inputStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
  ${(props) => (props.height ? `height: ${props.height}rem` : '')};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : '')};
  ${(touched) => (touched ? `color: var(--color-dark)` : '')}
`;

const DivSkeleton = styled.div<Partial<InputProps>>`
  ${inputStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : '')};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : '')};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : '')};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
`;

const WithIconSkeleton = styled.div`
  ${inputStyles.pwIcon};
`;

export default MimaInput;
