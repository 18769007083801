'use client';

import Image, { StaticImageData } from 'next/image';
import { useState } from 'react';
import { GrNext } from 'react-icons/gr';
import { GrPrevious } from 'react-icons/gr';
import styles from '@/assets/styles/components/ImageCarousel.module.scss';
import { myLoader } from '@/utils/utils';

interface ImageCarouselProps {
  images: StaticImageData[] | string[];
  width?: number;
  height?: number;
  handleClick?: () => void;
}

const ImageCarousel = ({ images, width = 250, height = 250, handleClick }: ImageCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className={styles.carousel}>
      <Image
        loader={myLoader}
        src={images?.[currentIndex]}
        alt={`Crochet photo ${currentIndex + 1}`}
        style={{
          objectFit: 'contain',
          width: width,
          height: height,
          borderRadius: '8px',
          cursor: 'pointer',
        }}
        width={width}
        height={height}
        onClick={handleClick}
      />

      <GrPrevious className={styles.carousel_arrowLeft} onClick={handlePrev} />

      <GrNext className={styles.carousel_arrowRight} onClick={handleNext} />
    </div>
  );
};

export default ImageCarousel;
