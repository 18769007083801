'use client';

import React, { FC, useState, useEffect, useMemo } from 'react';
import { FashMainLogo, FashWhiteLogo } from '@/assets/img/ImgList';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';
import { Countdown, MimaButton } from '@/components';
import Link from 'next/link';
import Image from 'next/image';
import styles from '@/assets/styles/components/Layout.module.scss';
import { HeaderNavItems } from './Navitems';
import { useSwitchNav } from './SwitchNav';
import { SiGnuprivacyguard } from 'react-icons/si';
import { CompetitionController } from '@/controllers';
import { ICompetitionListDTO } from '@/interfaces';
import { isDateWithinRange } from '@/utils/utils';

interface IHeaderProps {
  variant?: 'header1' | 'header2';
}

const Header: FC<IHeaderProps> = ({ variant = 'header1' }) => {
  const [menu, setMenu] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [showCountdown, setShowCountdown] = useState(false);

  const { switchToLanding, switchToSignup, switchToLogin, isCompetition, switchToRegister } = useSwitchNav();
  const navItems = useMemo(() => HeaderNavItems, []);

  const showMenu = () => setMenu(!menu);

  const [limit, setLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const query = useMemo(() => {
    return {
      offset: currentPage + 1,
      limit,
      ...(searchQuery ? { search: searchQuery } : {}),
    };
  }, [currentPage, limit, searchQuery]);

  const { data } = CompetitionController.useGetCompetitions(query);

  const competitionList = (data as ICompetitionListDTO)?.data?.competitions;
  const currentCompetition = competitionList?.filter((item: any) => item?.status === 'ACTIVE').map((competition: any) => competition);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        setIsTop(true); // User is at the top
      } else {
        setIsTop(false); // User has scrolled away from the top
      }
    };
    checkScrollPosition();

    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, []);

  // Countdown

  const countdownEndDate = useMemo(() => {
    if (!currentCompetition?.[0]?.stage) return null;

    switch (currentCompetition[0].stage) {
      case 'REG':
        return currentCompetition[0].registrationEndDate || null;
      case 'ONE':
        return currentCompetition[0].stageOneVotingEndDate || null;
      case 'TWO':
        return currentCompetition[0].stageTwoVotingEndDate || null;
      default:
        return null;
    }
  }, [currentCompetition]);

  useEffect(() => {
    const checkCountdownVisibility = () => {
      const endDate = new Date(countdownEndDate);

      const currentDate = new Date();

      const daysDifference = (endDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);

      if (daysDifference <= 2 && daysDifference >= -2) {
        setShowCountdown(true);
      } else {
        setShowCountdown(false);
      }
    };

    checkCountdownVisibility();

    const interval = setInterval(checkCountdownVisibility, 24 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const countdownTitle = useMemo(() => {
    if (!currentCompetition?.[0]?.stage) return 'Countdown';

    switch (currentCompetition[0].stage) {
      case 'REG':
        return 'Registration';
      case 'ONE':
      case 'TWO':
        return 'Voting';
      default:
        return 'Event';
    }
  }, [currentCompetition]);

  return (
    <header className={`${variant === 'header1' ? styles.header1 : styles.header2} ${isTop ? (menu ? styles.visible : styles.top) : styles.visible}`}>
      <nav className={styles.nav}>
        <Image src={variant === 'header1' ? FashWhiteLogo : FashMainLogo} className="w-72" alt="Fash Creative Logo" onClick={switchToLanding} />

        <ul className={styles.navMenu}>
          {navItems.map((navItem, i) => (
            <li key={i}>
              <Link href={`${navItem.link}${navItem.id}`}>{navItem.title}</Link>
            </li>
          ))}
        </ul>

        {isCompetition && showCountdown && <Countdown endDate={countdownEndDate} title={countdownTitle} />}

        <div className={styles.btnSet}>
          {currentCompetition?.[0]?.stage === 'REG' && isCompetition ? (
            <MimaButton
              title="Register"
              icon={<SiGnuprivacyguard />}
              onClick={switchToRegister}
              variant="outlined"
              buttonColor="var(--color-white)"
              width={18}
              height={6}
            />
          ) : !isCompetition ? (
            <>
              <MimaButton
                title="Log in"
                variant={variant === 'header1' && isTop ? 'regular' : 'outlined'}
                buttonColor={variant === 'header1' && isTop ? 'var(--color-white)' : ''}
                titleColor={isTop ? 'var(--color-dark)' : ''}
                width={19.5}
                onClick={switchToLogin}
              />
              <MimaButton title="Sign up" onClick={switchToSignup} width={19.5} />
            </>
          ) : null}
        </div>

        <div onClick={showMenu} className={styles.hamburger} aria-controls="mobile-menu" aria-expanded={menu} aria-label="Toggle navigation">
          {menu ? <IoMdClose /> : <IoMdMenu />}
        </div>
      </nav>

      <AnimatePresence>
        {menu && (
          <motion.div
            initial={{ opacity: 0, y: '-100%' }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, y: '-100%', transition: { duration: 0.3 } }}
            className={styles.mobileNav}
          >
            <ul className={styles.mobileNaveMenu}>
              {navItems.map((navItem, i) => (
                <li key={i} onClick={showMenu}>
                  <Link href={`${navItem.link}${navItem.id}`}>{navItem.title}</Link>
                </li>
              ))}
            </ul>

            {currentCompetition?.[0]?.stage === 'REG' && isCompetition ? (
              <MimaButton
                title="Register"
                icon={<SiGnuprivacyguard />}
                onClick={switchToRegister}
                variant="outlined"
                buttonColor="var(--color-white)"
                width={18}
                height={6}
              />
            ) : !isCompetition ? (
              <div className={styles.btnSet}>
                <MimaButton title="Log in" variant="outlined" onClick={switchToLogin} mb={2} width={32} />
                <MimaButton title="Sign up" variant="fullWidth" onClick={switchToSignup} width={32} />
              </div>
            ) : null}
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
