import { useRef } from 'react';
import styles from '@/assets/styles/pages/home/Home.module.scss';
import { useSwitchNav } from '@/components/PageLayout/_PageLayoutComponents/SwitchNav';
import { FashButton, MimaText } from '@/components';
import { Benefit1, Benefit2, Benefit3, Benefit4, Benefit5 } from '@/assets/img/home/Benefit images';
import Image, { StaticImageData } from 'next/image';
import { useInView } from 'framer-motion';
import { FaArrowRight } from 'react-icons/fa6';

interface CardProps {
  title: string;
  description: React.ReactNode;
  image: StaticImageData; // or string if using a URL for the image
}

const Card: React.FC<CardProps> = ({ title, description, image }) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const isInView = useInView(cardRef, { once: true, amount: 0.5 });

  // useEffect(() => {
  //   const currentCardRef = cardRef.current; // Copy the ref value to a variable

  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           entry.target.classList.add(styles.fadeIn);
  //         }
  //       });
  //     },
  //     { threshold: 0.1 }
  //   );

  //   if (currentCardRef) {
  //     observer.observe(currentCardRef);
  //   }

  //   // Cleanup function: Use the saved ref variable
  //   return () => {
  //     if (currentCardRef) {
  //       observer.unobserve(currentCardRef);
  //     }
  //   };
  // }, []); // No need for `cardRef` in the dependencies

  return (
    <div className={`${styles.card} ${isInView ? styles.fadeIn : ''}`} ref={cardRef}>
      <div className={styles.textArea}>
        <MimaText variant="subtitleBold" color="var(--color-dark-4)" className={styles.subtitle}>
          {title}
        </MimaText>
        <MimaText variant="body">{description}</MimaText>
      </div>
      <Image src={image} alt="Benefit image" width={220} height={180} />
    </div>
  );
};

const Benefit = () => {
  const { switchToSignup } = useSwitchNav();

  const BenefitData = [
    {
      title: "Do you struggle with expanding your brand's presence and increasing sales?",
      description: (
        <>
          <span>With Fash Creatives you can create a free website</span> that enhances your brand&apos;s credibility and reaches a global market. With
          our tools, your site will be optimized for international sales, making it accessible worldwide and helping you grow your sales.
        </>
      ),
      image: Benefit1,
    },
    {
      title: 'Are delayed payments and currency exchange issues affecting your cash flow?',
      description: (
        <>
          <span>Using Fash Creatives you receive your global payments immediately</span> ensuring smooth operations. Our Multi-Currency Access and
          Wallet features simplify currency exchanges, helping you manage international sales without hassle.
        </>
      ),
      image: Benefit2,
    },
    {
      title: 'Are you facing challenges in finding trusted shipping solutions for international orders?',
      description: (
        <>
          <span>Our Global Logistics</span> feature connects you with a network of trusted partners, making both local and international deliveries
          seamless and reliable.
        </>
      ),
      image: Benefit3,
    },
    {
      title: 'Are missed orders and late shipments hurting your business?',
      description: (
        <>
          <span>With Fash creatives Order Management is simplified,</span> you can easily manage all your orders and receive timely reminders,
          ensuring you never miss a deadline and keep your customers satisfied.
        </>
      ),
      image: Benefit4,
    },
    {
      title: 'Do you struggle with managing payments and bookings efficiently?',
      description: (
        <>
          <span>Our Payment Link feature</span> allows you to create customized payment links, simplifying transactions for your customers. Paired
          with our <span>Booking Link</span>, you can easily manage reservations and bookings, keeping everything organized and stress-free.
        </>
      ),
      image: Benefit5,
    },
  ];

  return (
    <section className={styles.benefit} id="benefits">
      <div className={styles.container}>
        <div className={styles.title}>
          <MimaText variant="h2" color="var(--color-white)" className={styles.titleText}>
            How does <span>Fash</span> creatives help you make more money.
          </MimaText>
          <FashButton title="Start A Free Trial Now" icon color="var(--color-primary-2)" onClick={switchToSignup} />
        </div>
        <div className={styles.benefitList}>
          {BenefitData.map((benefit, index) => (
            <Card key={index} title={benefit.title} description={benefit.description} image={benefit.image} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefit;
