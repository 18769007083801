'use client';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { dropdownStyles } from './styles';
import { MimaCheckBox, MimaSearch } from '..';
import { FaCaretDown } from 'react-icons/fa6';
import { Controller } from 'react-hook-form';
import styles from '@/assets/styles/components/MimaMultiDropdrown.module.scss';
import { MdClose } from 'react-icons/md';

interface DropdownProps {
  labelTitle: string;
  placeholder: string;
  id?: string;
  value?: any;
  name: string;
  onBlur?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  variant?: 'medium' | 'form' | 'error' | 'fullWidthError';
  width?: number;
  height?: number;
  icon?: React.ReactNode;
  data: DropdownData[] | MultiDropdownData[]; // Adjust the type based on the actual type of your data
  styleClass?: any;
  pt?: any;
  pb?: any;
  error?: any;
  touched?: any;
  mb?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  fontSize?: number;
  control: any;
  setValue: any;
  //for edit
  defaultValue?: string[];
}

interface DropdownData {
  key: string;
  value: string;
}

export type MultiDropdownData = {
  headTitle: string;
  values: DropdownData[];
};

const MimaMultiDropdown: React.FC<DropdownProps> = ({
  labelTitle,
  placeholder,
  data = [
    { key: 'item 1', value: 'item 1' },
    { key: 'item 2', value: 'item 2' },
  ],
  onBlur,
  onChange,
  value,
  variant = 'medium',
  width,
  id,
  name,
  touched,
  error,
  mb,
  mt,
  mr,
  ml,
  height,
  control,
  setValue,
  disabled = false,
  defaultValue,
  ...props
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selected, setSelected] = useState<DropdownData>({ key: '', value: placeholder });

  const noHeaderData = data as DropdownData[];
  const withHeaderData = data as MultiDropdownData[];
  const multiDataHeadTitle = (data[0] as MultiDropdownData)?.headTitle;

  useEffect(() => {
    const editing = () => {
      if (defaultValue && defaultValue?.length > 0 && !multiDataHeadTitle) {
        const filterDataArray = noHeaderData.filter((item) => defaultValue.includes(item.value));
        setTags([...filterDataArray]);
      } else if (defaultValue && defaultValue?.length > 0 && multiDataHeadTitle) {
        const withHeaderFilteredData: DropdownData[] = withHeaderData.reduce((result: DropdownData[], item: MultiDropdownData) => {
          const filteredValues: DropdownData[] = item.values.filter((value: DropdownData) => defaultValue.includes(value.value));

          if (filteredValues.length > 0) {
            result.push(...filteredValues);
          }

          return result;
        }, []);

        setTags([...withHeaderFilteredData]);
      }
    };

    editing();
  }, [defaultValue, multiDataHeadTitle, noHeaderData, withHeaderData]);

  const openDropdownHandler = () => {
    setOpenDropdown(!openDropdown);
  };

  const [called, setCalled] = useState(0);

  const dropDownSetHandler = (dropDownOption: DropdownData) => {
    setCalled(called + 1);
    // setSelected(dropDownOption);
    addTags(dropDownOption);
  };

  const dropDownOptions = data;
  const [filteredDropdown, setFilteredDropdown] = useState<any[]>([]);

  const [tags, setTags] = useState<DropdownData[]>([]);
  const [keyArray, setKeyArray] = useState<string[]>([]);

  //Remove tags
  const removeTags = (index: number) => {
    const updateTags = [...tags.filter((tag) => tags.indexOf(tag) !== index)];
    const keysArray = updateTags.map((item) => item.key);
    const valuesArray = updateTags.map((item) => item.value);

    setTags(updateTags);
    setValue(name, [...valuesArray]);
  };

  //Add tags
  const addTags = (dropDownOption: DropdownData) => {
    const existingIndex = tags.findIndex((item) => item.key === dropDownOption.key);

    if (existingIndex !== -1) {
      const refinedTags = [...tags];
      refinedTags.splice(existingIndex, 1);
      setTags(refinedTags);
      const keysArray = refinedTags.map((item) => item.key);
      const valuesArray = refinedTags.map((item) => item.value);

      setValue(name, [...valuesArray]);
    } else {
      // setTags([...tags, dropDownOption]);
      setTags((prevTags) => [...prevTags, dropDownOption]);
      const tagKeysArray = tags.map((item) => item.key);
      const tagValuesArray = tags.map((item) => item.value);
      const keysArray = [...tagKeysArray, dropDownOption.key];
      const valuesArray = [...tagValuesArray, dropDownOption.value];

      setValue(name, [...valuesArray]);
    }
  };

  const sampleWithHead = [
    {
      headTitle: 'main cat',
      values: [
        { key: 'item 1', value: 'item 1' },
        { key: 'item 2', value: 'item 2' },
        { key: 'itemitemitem 2', value: 'itemitemitem 2' },
        { key: 'itemitemitem 3', value: 'itemitemitem 3' },
        { key: 'itemitemitem 4', value: 'itemitemitem 4' },
        { key: 'itemitemitem 5', value: 'itemitemitem 5' },
      ],
    },
    {
      headTitle: 'another cat',
      values: [
        { key: 'item 1', value: 'item 1' },
        { key: 'item 2', value: 'item 2' },
        { key: 'itemitemitem 2', value: 'itemitemitem 2' },
        { key: 'itemitemitem 3', value: 'itemitemitem 3' },
        { key: 'itemitemitem 4', value: 'itemitemitem 4' },
        { key: 'itemitemitem 5', value: 'itemitemitem 5' },
      ],
    },
  ];

  return (
    <>
      <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
        <label className="label">{labelTitle}</label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <DropdownSkeleton
                width={width}
                height={height}
                placeholder={placeholder}
                onClick={openDropdownHandler}
                variant={error ? 'fullWidthError' : 'form'}
                onBlur={field.onBlur}
              >
                <div className={styles.tags}>
                  {tags.length > 0 ? (
                    <>
                      {tags.map((tag, index) => (
                        <div className={styles.singleTag} key={index}>
                          <span>{tag.value}</span>
                          <i
                            onClick={(e) => {
                              e.stopPropagation();
                              removeTags(index);
                              setValue(tag.key, false);
                            }}
                          >
                            <MdClose />
                          </i>
                        </div>
                      ))}
                    </>
                  ) : (
                    <p>{placeholder}</p>
                  )}
                </div>
                <DropIcon>
                  <FaCaretDown
                    className={openDropdown ? 'transition rotate-180 duration-500' : 'transition rotate-0 duration-500'}
                    onClick={() => {
                      setOpenDropdown(!openDropdown);
                    }}
                  />
                </DropIcon>
              </DropdownSkeleton>
              {openDropdown && (
                <DropdownBody
                  width={width}
                  onMouseLeave={() => {
                    setOpenDropdown(!openDropdown);
                  }}
                >
                  <MimaSearch
                    placeholder="Search"
                    input={dropDownOptions}
                    loading={false}
                    output={setFilteredDropdown}
                    handleFilter={
                      multiDataHeadTitle
                        ? (item, searchQuery) => {
                            const mainArrayCondition = item?.headTitle?.toLowerCase()?.includes(searchQuery);

                            return mainArrayCondition;
                          }
                        : (item, searchQuery) => {
                            const mainArrayCondition = item?.value?.toLowerCase()?.includes(searchQuery);

                            return mainArrayCondition;
                          }
                    }
                    variant="regular"
                    showIcon={false}
                    mb={1}
                    mt={2}
                  />
                  {multiDataHeadTitle ? (
                    <>
                      {dropDownOptions.length > 0 ? (
                        <>
                          {filteredDropdown.map((items, i) => (
                            <div key={i}>
                              <div className="cursor-pointer py-4 px-6 bg-color-table-grey text-default-font-small">{items.headTitle}</div>
                              {items.values.map((item: DropdownData) => (
                                <div key={item.key} className="cursor-pointer py-8 px-6 hover:bg-color-primary-3 hover:text-color-dark">
                                  <MimaCheckBox labelTitle={item.value} control={control} name={item.key} onClick={() => dropDownSetHandler(item)} />
                                </div>
                              ))}
                            </div>
                          ))}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      {dropDownOptions.length > 0 ? (
                        <>
                          {filteredDropdown.map((item) => (
                            <div key={item.key} className="cursor-pointer py-8 px-6 hover:bg-color-primary-3 hover:text-color-dark">
                              <MimaCheckBox labelTitle={item.value} control={control} name={item.key} onClick={() => dropDownSetHandler(item)} />
                            </div>
                          ))}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </DropdownBody>
              )}
            </>
          )}
        />

        {error ? <div className="text-color-error my-4 max-w-[38rem] text-default-font-small">{error.message}</div> : null}
      </DivSkeleton>
    </>
  );
};

const DropdownSkeleton = styled.div<Partial<DropdownProps>>`
  ${dropdownStyles.multiDivBase};
  ${(props) => props.variant && dropdownStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
  ${(touched) => (touched.id || touched.name ? `color: var(--color-dark)` : '')}
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : '')};
`;

const DropdownBody = styled.div<Partial<DropdownProps>>`
  ${dropdownStyles.multiBodyBase};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
`;

const DivSkeleton = styled.div<Partial<DropdownProps>>`
  ${dropdownStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : '')};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : '')};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : '')};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
`;

const DropIcon = styled.div`
  ${dropdownStyles.iconBase};
`;

export default MimaMultiDropdown;
