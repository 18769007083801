'use client';

import DotLoader from 'react-spinners/DotLoader';
import MimaText from '../MimaText';
import React from 'react';
import { BarLoader } from 'react-spinners';

interface LoaderProps {
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  loaderColor?: string;
  size?: number;
  variant?: 'dark' | 'light';
  type?: 'dot' | 'bar';
}

const PageLoader: React.FC<LoaderProps> = ({ title, loading = true, disabled = false, size = 50, variant = 'dark', type = 'dot' }) => {
  return (
    <>
      {type === 'dot' && (
        <>
          {variant === 'dark' && (
            <div className="loaderContainer">
              <MimaText variant="subtitleBold"> Please Wait</MimaText>
              <MimaText mb={3}> {title}</MimaText>
              <DotLoader color={`var(--color-primary)`} loading={loading} size={size} />
            </div>
          )}

          {variant === 'light' && (
            <div className="loaderContainer">
              <MimaText variant="subtitleBold" color="var(--color-white)">
                {' '}
                Please Wait
              </MimaText>
              <MimaText mb={3} color="var(--color-white)">
                {' '}
                {title}
              </MimaText>
              <DotLoader color={`var(--color-white)`} loading={loading} size={size} />
            </div>
          )}
        </>
      )}

      {type === 'bar' && (
        <>
          {variant === 'dark' && (
            <div className="loaderContainer">
              <MimaText variant="bodyBold"> Please Wait</MimaText>
              <MimaText mb={3}> {title}</MimaText>
              <BarLoader color={`var(--color-primary)`} loading={loading} />
            </div>
          )}

          {variant === 'light' && (
            <div className="loaderContainer">
              <MimaText variant="subtitleBold" color="var(--color-white)">
                {' '}
                Please Wait
              </MimaText>
              <MimaText mb={3} color="var(--color-white)">
                {' '}
                {title}
              </MimaText>
              <BarLoader color={`var(--color-white)`} loading={loading} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PageLoader;
