'use client';

import React, { ReactNode } from 'react';
import { Header, Footer } from './_PageLayoutComponents'; // Adjust the import paths as needed

interface LayoutProps {
  children: ReactNode;
}

const PageLayout1: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Header variant="header1" />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default PageLayout1;
