'use client';
import { userStore } from '@/stores';
import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import IconButton from '../IconButton';

export interface AppShellTopProps {
  pageTitle: string | undefined;
  menuClicked: () => void;
  goTologout: () => void;
}

const AppShellTop: React.FC<AppShellTopProps> = ({ menuClicked, pageTitle, goTologout }) => {
  const [tier, setTier] = useState('Tier 1');

  const tierClass = `${
    tier === 'Tier 2' ? 'bg-green-50' : tier === 'Tier 3' ? 'bg-grey-700' : 'bg-red-50'
  } text-[1rem] box-border p-[0.7rem] text-white rounded-[1rem] tablet:mr-4 mr-[2.7rem]`;

  return (
    <div className="flex justify-between items-center mb-8">
      <div className="flex items-center">
        {/* <FaBars
          className="text-[3rem] text-[#001432] cursor-pointer hover:text-primary-300 focus:text-primary-300 mr-8"
          onClick={menuClicked}
          data-testid="menu-icon"
        /> */}
        {pageTitle !== '' ? (
          <h3 className="text-default-font-h3 bp-600:text-default-font-subtitle bp-600:font-bold"> {pageTitle}</h3>
        ) : (
          <p className="text-default-font-small" data-testid="welcome">
            Welcome back, <br />
            <span className="text-default-font-subtitle text-primary-50 font-bold" data-testid="username">
              {userStore.user?.business?.tradingName}
            </span>
          </p>
        )}
      </div>

      {/* <div className="flex items-center flex-col-reverse tablet:flex-row">
        <p className={tierClass}>{tier} user</p>
      </div> */}
      <div className="flex items-center flex-col-reverse tablet:flex-row">
        {/* <p className={tierClass}>{tier} user</p> */}
        <div className="flex items-center mb-4 tablet:mb-0">
          {/* <IconButton variant="settings" onClick={goToSettings} testID="settings" /> */}
          <IconButton variant="logout" onClick={goTologout} testID="logout" />
        </div>
      </div>
    </div>
  );
};

export default AppShellTop;
