import constant from "@/utils/constant";

const BASE_APP_URL = process.env.NEXT_PUBLIC_BASE_APP_URL || '';


export const HeaderNavItems = [
    {
      title: 'Benefits',
      id: '#benefits',
      link: constant.Routes.Home,
    },
    {
      title: 'Pricing',
      id: '#pricing',
      link: constant.Routes.Home,
    },
    {
      title: 'Blog',
      id: '#',
      link: constant.Routes.BlogLanding,
    },
    {
      title: 'Competition',
      id: '#',
      link: constant.Routes.Competition,
    },
    
    // {
    //   title: 'Partnerships',
    //   id: '#',
    //   link: constant.Routes.PartnerHome,
    // },
    {
    	title: 'Contact',
    	id: '#Company',
    	link: constant.Routes.Contact,
    },
  ];


  export const FooterNavItems= [
    {
      main: 'Menu',
      subs: [
        {
          title: 'Create Style',
          link: `${BASE_APP_URL}${constant.Routes.SignUp}`,
        },
        {
          title: 'Public Catalog',
          link: `${BASE_APP_URL}${constant.Routes.SignUp}`,
        },
        {
          title: 'Save Measurements',
          link: `${BASE_APP_URL}${constant.Routes.SignUp}`,
        },
        {
          title: 'Receive Payments',
          link: `${BASE_APP_URL}${constant.Routes.SignUp}`,
        },
      ],
    },
    {
      main: 'Company',
      subs: [
        {
          title: 'About',
          link: constant.Routes.About,
        },
        // {
        //   title: 'FAQs',
        //   link: constant.Routes.Faqs,
        // },
        {
          title: 'Terms',
          link: constant.Routes.Terms,
        },
        {
          title: 'Privacy',
          link: constant.Routes.Privacy,
        },
      ],
    },
    {
      main: 'Legal',
      subs: [
        {
          title: 'Terms',
          link: constant.Routes.Terms,
        },
        {
          title: 'Privacy',
          link: constant.Routes.Privacy,
        },
      ],
    },


  ]