'use client';

import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { dropdownStyles } from './styles';
import { MimaSearch } from '..';
import { FaCaretDown } from 'react-icons/fa6';
import { Controller } from 'react-hook-form';
import { useWatch } from 'react-hook-form';

interface DropdownProps {
  labelTitle: string;
  placeholder?: string;
  id?: string;
  value?: any;
  name: string;
  onBlur?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  variant?: 'medium' | 'error' | 'fullWidth' | 'fullWidthError';
  width?: number;
  height?: number;
  icon?: React.ReactNode;
  data: DropdownData[]; // Adjust the type based on the actual type of your data
  styleClass?: any;
  pt?: any;
  pb?: any;
  error?: any;
  touched?: any;
  mb?: number;
  mt?: number;
  mr?: number;
  ml?: number;
  fontSize?: number;
  control: any;
  bodyVariant?: 'bodyBase' | 'bodyBase2';
  defaultValue?: string;
}

interface DropdownData {
  key: string;
  value: string;
  rgb?: string;
}

const MimaDropdown: React.FC<DropdownProps> = ({
  labelTitle,
  placeholder,
  data = [
    { key: 'item 1', value: 'item 1' },
    { key: 'item 2', value: 'item 2' },
  ],
  onBlur,
  onChange,
  value,
  variant = 'medium',
  width,
  id,
  name,
  touched,
  error,
  mb,
  mt,
  mr,
  ml,
  height,
  control,
  bodyVariant = 'bodyBase',
  disabled = false,
  defaultValue,
  ...props
}) => {
  useEffect(() => {
    const forEdits = () => {
      if (defaultValue) {
        const defaultData = data.filter((item) => item.key === defaultValue);

        setSelected(defaultData[0]);
      } else {
        setSelected({ key: '', value: placeholder ? placeholder : labelTitle });
      }
    };

    forEdits();
  }, [defaultValue]);

  const currentKey = useWatch({ control, name: name }) || '';

  // for persist

  useEffect(() => {
    const getCurrentValues = () => {
      const currentArray = data.filter((x) => x.key === currentKey);

      setSelected(currentArray[0]);
    };

    if (currentKey && !defaultValue) {
      getCurrentValues();
    }
  }, [data, currentKey, defaultValue]);

  const [openDropdown, setOpenDropdown] = useState(false);
  const [selected, setSelected] = useState<DropdownData>({ key: '', value: placeholder ? placeholder : labelTitle });

  const openDropdownHandler = () => {
    setOpenDropdown(!openDropdown);
  };

  const dropDownSetHandler = (dropDownOption: DropdownData, field: any) => {
    setSelected(dropDownOption);
    field.onChange(dropDownOption.key);
    setOpenDropdown(false);
  };

  const dropDownOptions = data;
  const [filteredDropdown, setFilteredDropdown] = useState<any[]>([]);

  const errorType = useMemo(() => {
    if (variant === 'fullWidth') {
      return 'fullWidthError';
    }

    return 'error';
  }, [variant]);

  return (
    <>
      <DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
        <label className="label">{labelTitle}</label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <DropdownSkeleton
                width={width}
                height={height}
                placeholder={placeholder || labelTitle}
                onClick={openDropdownHandler}
                variant={error ? errorType : variant}
                onBlur={field.onBlur}
              >
                {selected?.rgb ? (
                  <div
                    style={{
                      display: 'flex',
                      width: '3rem',
                      height: '3rem',
                      borderRadius: '3rem',
                      content: '',
                      backgroundColor: `rgba(${selected?.rgb})`,
                    }}
                  ></div>
                ) : (
                  ''
                )}
                {selected?.value}
                <DropIcon>
                  <FaCaretDown className={openDropdown ? 'transition rotate-180 duration-500' : 'transition rotate-0 duration-500'} />
                </DropIcon>
              </DropdownSkeleton>
              {openDropdown && (
                <DropdownBody width={width} bodyVariant={variant === 'fullWidth' ? 'bodyBase2' : 'bodyBase'}>
                  <MimaSearch
                    placeholder="Search"
                    input={dropDownOptions}
                    loading={false}
                    output={setFilteredDropdown}
                    handleFilter={(item, searchQuery) => {
                      return item?.value?.toLowerCase()?.includes(searchQuery);
                    }}
                    variant="dropdown"
                    showIcon={false}
                    mb={1}
                    mt={2}
                  />

                  {filteredDropdown.map((item) => (
                    <div
                      key={item.key}
                      onClick={() => dropDownSetHandler(item, field)}
                      className="cursor-pointer py-8 px-6 hover:bg-color-dark hover:text-color-white flex gap-4"
                    >
                      {item?.rgb ? (
                        <div
                          style={{
                            display: 'flex',
                            width: '3rem',
                            height: '3rem',
                            borderRadius: '3rem',
                            content: '',
                            backgroundColor: `rgba(${item?.rgb})`,
                          }}
                        ></div>
                      ) : (
                        ''
                      )}
                      {item.value}
                    </div>
                  ))}
                </DropdownBody>
              )}
            </>
          )}
        />

        {error ? <div className="text-color-error my-4 max-w-[38rem] text-default-font-small">{error.message}</div> : null}
      </DivSkeleton>
    </>
  );
};

const DropdownSkeleton = styled.div<Partial<DropdownProps>>`
  ${dropdownStyles.base};
  ${(props) => props.variant && dropdownStyles[props.variant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
  ${(touched) => (touched.id || touched.name ? `color: var(--color-dark)` : '')}
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : '')};
`;

const DropdownBody = styled.div<Partial<DropdownProps>>`
  ${dropdownStyles.bodyBase};
  ${(props) => props.bodyVariant && dropdownStyles[props.bodyVariant]};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
`;

const DivSkeleton = styled.div<Partial<DropdownProps>>`
  ${dropdownStyles.divBase};
  ${(props) => (props.mt ? `margin-top: ${props.mt}rem` : '')};
  ${(props) => (props.mr ? `margin-right: ${props.mr}rem` : '')};
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
  ${(props) => (props.ml ? `margin-left: ${props.ml}rem` : '')};
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}rem` : '')};
  ${(props) => (props.width ? `width: ${props.width}rem` : '')};
`;

const DropIcon = styled.div`
  ${dropdownStyles.iconBase};
`;

export default MimaDropdown;
