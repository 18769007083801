'use client';

import styles from '@/assets/styles/components/NewModal.module.scss';
import { motion } from 'framer-motion';
import React from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';

interface ModalProps {
  children: React.ReactNode;
  closeModal: () => void;
  goBack?: () => void;
  title: string;
}

const NewModal: React.FC<ModalProps> = ({ children, closeModal, goBack, title, ...props }) => {
  return (
    <RemoveScroll>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.2,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.2,
          },
        }}
        className={styles.background}
      >
        <div onClick={closeModal}></div>

        <motion.div
          initial={{
            y: -100, // Start above the screen
          }}
          animate={{
            y: 0, // Drop to the center
            transition: {
              duration: 0.3,
              type: 'spring',
              damping: 25,
              stiffness: 500,
            },
          }}
          exit={{
            y: 100, // Exit to the bottom
            transition: {
              duration: 0.2,
              type: 'spring',
              damping: 25,
              stiffness: 500,
            },
          }}
          className={styles.container}
        >
          <div className={styles.container__top}>
            {title ? <h4>{title.toUpperCase()}</h4> : ''}
            {goBack && <BsFillArrowLeftCircleFill onClick={goBack} style={{ position: 'fixed' }} />}
            <div className={styles.close}>
              <IoMdClose onClick={closeModal} />
            </div>
          </div>
          <div className={styles.content}>{children}</div>
        </motion.div>
      </motion.div>
    </RemoveScroll>
  );
};

export default NewModal;
