'use client';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import styles from '@/assets/styles/components/MimaDropzone.module.scss';
import Image from 'next/image';
import { MdCancel } from 'react-icons/md';
import { MimaText } from '..';

interface DropzoneProps {
  labelTitle: string;
  title: string;
  info?: string;
  info2?: string;
  maxNumOfImages: number;
  files: FileWithPreview[];
  name?: string;
  setFiles: Dispatch<SetStateAction<(previousFiles: FileWithPreview[]) => any[]>> | Dispatch<React.SetStateAction<FileWithPreview[]>>;

  errorMsg?: string;
  setValue: any;
  maxSize: number;
}

export interface FileWithPreview extends File {
  preview?: string;
}

const MimaDropZone: React.FC<DropzoneProps> = ({
  title,
  info,
  info2,
  maxNumOfImages,
  files,
  setFiles,
  labelTitle,
  name,
  errorMsg,
  setValue,
  maxSize = 1, //1mb
}) => {
  const [rejected, setRejected] = useState<FileRejection[]>([]);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    const filesWithPreview = acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
    setFiles((previousFiles: FileWithPreview[]) => [...previousFiles, ...filesWithPreview]);

    if (rejectedFiles?.length) {
      setRejected((previousFiles) => [...previousFiles, ...rejectedFiles]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
      'video/*': [],
    },
    maxSize: 1024 * 1024 * maxSize,
    maxFiles: maxNumOfImages,
    onDrop,
  });

  const removeFile = (name: string) => {
    setFiles((files: FileWithPreview[]) => files.filter((file) => file.name !== name));
  };

  useEffect(() => {
    const removeRejected = () => {
      if (rejected.length > 0) {
        setTimeout(() => {
          setRejected([]);
        }, 5000);
      }
    };

    removeRejected();
  }, [rejected]);

  return (
    <div className={styles.group}>
      <label htmlFor={name} className={styles.label}>
        {labelTitle}
      </label>
      <div className={styles.outline}>
        {files.length < maxNumOfImages ? (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div className={styles.content}>
                <div className={styles.upload}>{title}</div>
                <p>{info}</p>
                <p>{info2}</p>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        <div className={styles.preview}>
          {files.map((file) => (
            <div key={file.name}>
              <Image
                src={file.preview ? file.preview : ''}
                alt={`Preview ${file.name}`}
                onLoad={() => {
                  file.preview ? URL.revokeObjectURL(file.preview) : '';
                }}
                width={100}
                height={100}
              />
              <p>{file.name}</p>
              <button
                onClick={() => {
                  removeFile(file.name);
                  setValue('imgChanged', true);
                }}
                className={styles.remove}
              >
                <MdCancel />
              </button>
            </div>
          ))}
        </div>

        <div>
          {rejected.map(({ file, errors }) => (
            <div key={file.name} className={styles.rejected}>
              <p>{file.name} cannot be uploaded</p>
              {errors.map((error) => (
                <p key={error.code} className={styles.rejected__msg}>
                  {error.message}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
      {errorMsg ? (
        <MimaText variant="small" color="var(--color-error)" mt={1}>
          {errorMsg}
        </MimaText>
      ) : (
        ''
      )}
    </div>
  );
};

export default MimaDropZone;
