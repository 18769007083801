import React, { useEffect, useState } from 'react';
import styles from '@/assets/styles/components/CountDown.module.scss';

interface CountdownProps {
  endDate: string;
  onComplete?: () => void;
  title: string;
}

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const Countdown: React.FC<CountdownProps> = ({ endDate, onComplete, title }) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(calculateTimeLeft(endDate));

  function calculateTimeLeft(date: string): TimeLeft | null {
    const difference = new Date(date).getTime() - new Date().getTime();

    if (difference <= 0) {
      onComplete?.();
      return null;
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  if (!timeLeft) {
    return <span className={styles.countdown}>{title} has ended!!!</span>;
  }

  return (
    <div className={styles.countdown}>
      <span>Hurry Up! {title} ends in </span>
      <span className={styles.countdown_time}>{timeLeft.days}d </span>
      <span className={styles.countdown_time}>{timeLeft.hours}h </span>
      <span className={styles.countdown_time}>{timeLeft.minutes}m </span>
      <span className={styles.countdown_time}>{timeLeft.seconds}s</span>
    </div>
  );
};

export default Countdown;
