'use client';

import React from 'react';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { RemoveScroll } from 'react-remove-scroll';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { AiFillCloseCircle } from 'react-icons/ai';
import styles from '@/assets/styles/components/Modal.module.scss';

interface ModalProps {
  children: React.ReactNode;
  closeModal: () => void;
  goBack?: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, closeModal, goBack }) => {
  const isBelow500 = useMediaQuery({
    query: '(max-width: 500px)',
  });
  return (
    <RemoveScroll>
      {!isBelow500 && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }}
          className={styles.background}
        >
          <div onClick={closeModal}></div>

          <motion.div
            initial={{
              x: 100,
            }}
            animate={{
              x: 0,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              x: 100,
              transition: {
                duration: 0.2,
              },
            }}
            className={styles.container}
          >
            <div className={styles.container__top}>
              {goBack && <BsFillArrowLeftCircleFill onClick={goBack} />}
              <div className={styles.close}>
                <AiFillCloseCircle onClick={closeModal} />
              </div>
            </div>
            <div className={styles.content}>{children}</div>
          </motion.div>
        </motion.div>
      )}

      {isBelow500 && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }}
          className={styles.background}
        >
          <div onClick={closeModal}></div>

          <div className={styles.container__cover}>
            <motion.div
              initial={{
                y: 100,
              }}
              animate={{
                y: 0,
                transition: {
                  duration: 0.2,
                },
              }}
              exit={{
                y: 100,
                transition: {
                  duration: 0.2,
                },
              }}
              className={styles.container}
            >
              <div className={styles.container__top}>
                {goBack && <BsFillArrowLeftCircleFill onClick={goBack} />}
                <div className={styles.close}>
                  <AiFillCloseCircle onClick={closeModal} />
                </div>
              </div>

              {/* <div className={styles.bws}>a</div> */}
              <div className={styles.content}>{children}</div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </RemoveScroll>
  );
};

export default Modal;
