import { Data, IDefaultObject } from '@/interfaces';
import axios from 'axios';
import { useEffect, useState } from 'react';
import constant from './constant';
import { MimaToastUtil } from '@/components';
import { BusinessController } from '@/controllers';
import { ImageLoaderProps } from 'next/image';

export const amountFormatter = (currency: string) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'NGN',
  });

export const phoneRegExp = /^(\+?[0-9] ?(?:[0-9] ?){5,13}[0-9]|\d{11})$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>\[\]_\-+=;'\/\\])[A-Za-z\d!@#$%^&*(),.?":{}|<>\[\]_\-+=;'\/\\]+$/;

export const accountNoRegex = /^\d{10}$/;

export const removeEmptyKeys = (payload: IDefaultObject) => {
  for (const key in payload) {
    if (payload[key] && typeof payload[key] === 'object') {
      removeEmptyKeys(payload[key]);

      if (Object.keys(payload[key]).length === 0) {
        delete payload[key];
      }
    } else if (payload[key] === undefined || payload[key] === null || payload[key] === '') {
      delete payload[key];
    }
  }
};

export const handleImageUpload = async (files: File[]): Promise<string[] | 'Failed'> => {
  const uploadedUrls: string[] = [];

  for (let i = 0; i < files.length; i++) {
    const formData = new FormData();
    formData.append('assets', files[i]);

    const { data, status, errorMessage } = await BusinessController.uploadDocument(formData);

    if (status === constant.Success) {
      if (Array.isArray(data)) {
        uploadedUrls.push(...data); // Safely spread the array
      } else {
        MimaToastUtil.error({ message: 'Invalid response from server: Data is not an array' });
        return constant.Failed;
      }
    } else {
      MimaToastUtil.error({ message: errorMessage || 'Upload failed' });
      return constant.Failed;
    }
  }

  return uploadedUrls;
};

export function parseRequestQueryParamsIntoSearchParams(query: Data) {
  const searchParams = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (value === undefined || value === '' || value === null) return;
    if (!Number.isNaN(value) && typeof value === 'number') {
      value = value.toString();
    }
    searchParams.append(key, String(value));
  });
  return searchParams;
}

export const isDateWithinRange = (startDate: string | Date, endDate: string | Date): boolean => {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    return false;
  }

  return now >= start && now <= end;
};

export const myLoader = ({ src }: ImageLoaderProps): string => {
  return src;
};

// export const handleImageUpload = async (files: File[]): Promise<string[] | 'Failed'> =>  {
//   const uploadedUrls = [];

//   for (let i = 0; i < files.length; i++) {
//     const formData = new FormData();
//     formData.append("assets", files[i]);

//     const { data, status, errorMessage } =
//       await BusinessController.uploadDocument(formData);

//     if (status === constant.Success) {
//       uploadedUrls.push(...data); // Assuming 'data' is an array of URLs as strings
//     } else {
//       MimaToastUtil.error({ message: errorMessage });
//       return constant.Failed;
//     }
//   }

//   return uploadedUrls;
// };
