'use client';

import React, { memo } from 'react';
import { ErrorMessage } from './styles';
import Theme from '../Theme';
import OtpInput from 'react-otp-input';

const { Colors } = Theme;

export type OtpInputT = {
  title?: string;
  value?: string;
  inputType?: 'tel' | 'password';
  boxCount?: number;
  shouldAutoFocus?: boolean;
  onChange: (otp: string) => void;
  errorMessage?: string;
  className?: string;
  style?: React.CSSProperties;
};

const containerStyle = {
  gap: '2rem',
};

const inputStyle = {
  width: '6rem',
  height: '6rem',
  flexShrink: 0,
  borderRadius: '1rem',
  border: `1px solid ${Colors.grey[700]}`,
};

const MimaOtpInput = ({ title, inputType = 'tel', boxCount = 5, errorMessage, style, className, ...rest }: OtpInputT): JSX.Element => {
  const handlePaste: React.ClipboardEventHandler = (event: React.ClipboardEvent<Element>) => {
    const data = event.clipboardData.getData('text');
    rest.onChange(data);
  };

  return (
    <div className={`block ${className}`} style={style}>
      {title ? <p className={`block mb-2 ml-8 text-md font-medium text-grey-700`}>{title}</p> : null}
      <OtpInput
        {...rest}
        numInputs={boxCount}
        onPaste={handlePaste}
        inputType={inputType}
        inputStyle={inputStyle}
        containerStyle={containerStyle}
        renderInput={(props: any) => <input {...props} />}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default memo(MimaOtpInput);
